import React from 'react'
import YouTubeEmbed from '../components/HomePage/Youtube/Youtube'
import useScroll from "../hooks/useScroll"
import PageProps from '../types/PageProps'
import Sidebar from '../components/HomePage/Sidebar/Sidebar'
import Header from '../components/HomePage/Header/Header'
import ButtonToTop from '../components/ButtonToTop/ButtonToTop'
import Footer from '../components/HomePage/Footer/Footer'

const Teatro: React.FC<PageProps> = ({ targetRef, active, setActive }) => {
  useScroll(targetRef, setActive)
  return (
    <>
      <Header active={active} />
      <Sidebar />
      <div className="main-page__container align-items-center">
        <div className="main-page__image-container">
          <img className="main-page__image" src="/images/Marioteatro.jpg" alt="Mario Puglisi" ref={targetRef} />
        </div>
        <div className="main-page__text-wrapper">
          <p className="main-page__text">
            <em>”L&#39;interpretazione di un personaggio è un viaggio dentro le verità che ci riguardano. Ergo l&#39;esercizio della recitazione è alla base di un&#39;autentica interpretazione canora”<br />
              - M.P.</em><br /><br />

            Nel 2013 si cimenta per la prima volta nei panni di attore/cantante nel Musical “<em>La scalata della Piramide di Sale</em>”, tratta dall&#39;omonimo libro di Antonio Conticello.<br />
            <br />
            Nel 2014, con la compagnia teatrale <em><span className="text-decoration-underline">Redicuori</span></em>, interpreta il ruolo di "Marco Anselmi" nella commedia musicale <span className="main-page__text__span">“Tango Monsieur”</span>, di Aldo Lo Castro, ruolo per il quale è candidato <span className="main-page__text__span">miglior attore protagonista</span> al premio <span className="main-page__text__span">Aci e Galatea</span>.
            <br /><br />

            Nel 2023 si cimenta nei panni di co-autore/regista in una riedizione della commedia “La scalata della Piramide di Sale”, della quale è anche compositore delle musiche.
          </p>
        </div>
      </div>
      <div className="embeds-wrapper d-flex">
        <figure className="youtube-embed__container">
          <YouTubeEmbed videoId="KcFq0Qn6IzA" title="La Scalata della Piramide di Sale" />
          <figcaption className="embed__text mt-2">Presentazione "La Scalata della Piramide di Sale"</figcaption>
        </figure>
      </div>
      <ButtonToTop active={active} />
      <Footer />
    </>
  )
}

export default Teatro
