import { createBrowserRouter, RouterProvider } from 'react-router-dom';
import HomePage from '../pages/HomePage';
import Cantautore from '../pages/Cantautore';
import Teatro from '../pages/Teatro';
import Passato from '../pages/Passato';
import PageProps from '../types/PageProps';
import Error from '../components/Error/Error';
import Player from '../pages/Player';
import insAnc from '../data/IA';
import Incantu from '../data/Incantu';
import Salemi from '../data/Salemi';

const RouterElem: React.FC<PageProps> = ({ targetRef, active, setActive }) => {
    const router = createBrowserRouter([
        {
            path: '/',
            element: <HomePage targetRef={targetRef} active={active} setActive={setActive} />
        },
        {
            path: '/cantautore',
            element: <Cantautore targetRef={targetRef} active={active} setActive={setActive} />
        },
        {
            path: '/teatro',
            element: <Teatro targetRef={targetRef} active={active} setActive={setActive} />
        },
        {
            path: '/tempo-fa',
            element: <Passato targetRef={targetRef} active={active} setActive={setActive} />
        },
        {
            path: '/cd01',
            element: <Player songs={insAnc} imageSrc='/images/MP_Portraits-4 - Piccola.jpg' title="Insegnami Ancora"/>
        },
        {
            path: '/progetto-incantu',
            element: <Player songs={Incantu} imageSrc='/images/incantu.jpg' title="InCantu di Storie"/>
        },
        {
            path: '/salemimusica',
            element: <Player songs={Salemi} imageSrc='/images/CopertinaCDSalemi.jpg' title="My New Magic Age" presentation="<span class='fst-italic'>Tanti anni fa abbandonai la passione per la musica 
per intraprendere la professione di medico.<br/>
Oggi, in questa “Mia nuova magica età”
ho riaperto le porte a quella passione.<br/>

Ma questo disco nasce senza alcuna velleità, 
se non quella di lasciare un ricordo ai miei cari.<br/>
Per gli arrangiamenti mi sono avvalso della 
collaborazione di un amico: Mario Puglisi.</span>
            "/>
        },
        {
            path: '*',
            element: <Error active={active} setActive={setActive} targetRef={targetRef}/>
        }
    ])
    return (
        <div className="main">
            <RouterProvider router={router} />
        </div>
    );
}
export default RouterElem