import { faArrowUp } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React from 'react'

interface ButtonToTopProps {
    active: boolean;
}
const ButtonToTop: React.FC<ButtonToTopProps> = ({active}) => {
    document.querySelector(".arrow-up.nav-tog-button")?.addEventListener("click", () => {
        window.scrollTo(0, 0);
    })
    return (
        <div className={`arrow-up nav-tog-button${active ? " arrow-up-active" : ""}`}>
            <FontAwesomeIcon icon={faArrowUp} style={{ color: 'red', width: "1.5em", height: "1.5em" }} className="arrow-up" />
            </div>
    )
}

export default ButtonToTop
