import React from 'react'
import Header from '../HomePage/Header/Header'
import Sidebar from '../HomePage/Sidebar/Sidebar'
import PageProps from '../../types/PageProps'
import "./Error.css"
import Footer from '../HomePage/Footer/Footer'

const Error: React.FC<PageProps> = ({ active, setActive, targetRef }) => {
  return (
    <>
    <Header active={active} />
      <Sidebar />
      <div className="error-page error-page__wrapper flex-column" ref={targetRef}>
        <div className="error-page response-code">404</div>
        <div className="error-page__content">
          <div className="response__text">Pagina non trovata</div>
          <a href="/" className="error-page__btn"><div className="response__btn">Clicca qui per tornare alla homepage</div></a>
        </div>
      </div>
      <Footer />
    </>
  )
}

export default Error
