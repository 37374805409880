import { useRef, useState } from 'react';
import './App.css';
import RouterElem from './router/Router';

function App() {
  const targetRef = useRef<HTMLImageElement>(null);
  const [active, setActive] = useState(false);

  return (
    <>
      <RouterElem targetRef={targetRef} active={active} setActive={setActive}  />
    </>
  )

}

export default App;
