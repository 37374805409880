import React from 'react'
import YouTubeEmbed from '../Youtube/Youtube'
import './Main.css'
import useScroll from '../../../hooks/useScroll'
import PageProps from '../../../types/PageProps'

const Main: React.FC<PageProps> = ({ targetRef, setActive }) => {
    useScroll(targetRef, setActive)
    return (
        <>
            <div className="main-page__wrapper">
                <div className="main-page__container">
                    <div className="main-page__image-container">
                        <img className="main-page__image" id="main-page__image" src="/images/Mariomap.jpg" alt="Mario Puglisi" ref={targetRef} />
                    </div>
                    <div className="main-page__text-wrapper">
                        <p className="main-page__text">Mario Puglisi è un cantautore siciliano, autore di commedie musicali, attore e regista.
                            <br />
                            Ha partecipato sin da giovane al Festival della nuova canzone siciliana, cercando sempre di apportare autenticità poetica alla canzone dialettale.<br /> I suoi sforzi vengono premiati con la vittoria dell&#39;ottava edizione.
                            <br />
                            <br />
                            Ha scritto canzoni per altri interpreti come <span className="main-page__text__span">Francesca Alotta, Ana Flora e Fabio Concato.</span>
                            <br />

                            Nel 2012 esce il suo primo album: <em>“Insegnami ancora”.</em>
                            <br />
                            <br />
                            Da sempre appassionato di recitazione, nel 2013 debutta come attore/cantante nella commedia musicale “La scalata della piramide di sale”. Nel 2014 recita come attore protagonista in “Tango Monsieur”.<br /><br />
                            Nel 2022 debutta come regista teatrale.</p>
                    </div>
                </div>
            </div>
            <div className="embeds-wrapper d-flex">
                <figure className="youtube-embed__container">
                    <YouTubeEmbed videoId="h4BaDUcIdF4" title="Showreel Mario Puglisi"/>
                    <figcaption className="embed__text">Showreel Mario Puglisi</figcaption>
                </figure>

                <figure className="youtube-embed__container">
                    <YouTubeEmbed videoId="NJ5lntT1EnE" title="'A Sirena d'Auruca"/>
                    <figcaption className="embed__text">Scritto e Arrangiato da Mario Puglisi<br />
                        Sound engineer: Michele Antoniou<br />
                        Liberamente tratta dal racconto “La sirena” di G. Tomasi di Lampedusa.<br />
                        Regia di Luca Musumeci<br />
                        Riprese di Luca Musumeci e Luigi Pitari</figcaption>
                </figure>
            </div>
        </>
    )
}

export default Main
