import React, { useEffect, useState } from 'react'
import "./Header.css"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBars } from '@fortawesome/free-solid-svg-icons';

interface HeaderProps {
    active: boolean
}

const Header: React.FC<HeaderProps> = ({ active }) => {
    const [title, setTitle] = useState<string | null>(null);
    useEffect(() => {
        const currentWindow = window.location.pathname;
        const handlePageChange = (item: string) => {
            document.querySelectorAll(item).forEach((el) => {
                const element = el as HTMLAnchorElement
                if(currentWindow === "/cantautore"){
                    document.querySelector(".main")?.classList.add("inv-bg");
                    element.classList.add("inv-bg");
                } 
                if(currentWindow === "/teatro") {
                    document.querySelector(".main")?.classList.add("dia-bg");
                    element.classList.add("dia-bg");
                }
                if (element.getAttribute("href") === currentWindow) {
                    element.classList.add("current-item");
                    setTitle(element.dataset.value ? element.dataset.value : null);
                }
                else {
                    element.classList.remove("current-item");
                } 
                
            })
        }
        handlePageChange(".nav-link");
        handlePageChange(".offcanvas-link");
    }, []);

    return (
        <>
            <nav id="navbar" className={`navbar d-flex justify-content-between navbar-expand-lg nav-container ${active ? "active" : ""}`}>
                <a className="navbar-title" href="/">Mario Puglisi</a>

                <button className="nav-tog-button navbar-toggler" type="button" data-bs-toggle="offcanvas" data-bs-target="#offcanvasExample" aria-controls="offcanvasExample">
                    <FontAwesomeIcon icon={faBars} style={{ color: 'red', width: "20px" }} />
                </button>

                <div className="collapse navbar-collapse" id="navbarNav">
                    <ul className="navbar-nav ms-auto">
                        <li className="nav-item">
                            <a className="nav-link" href="/" data-value={null}>HOME</a>
                        </li>
                        <li className="nav-item">
                            <a className="nav-link" href="/cantautore" data-value="Cantautore">CANTAUTORE</a>
                        </li>
                        <li className="nav-item">
                            <a className="nav-link" href="/teatro" data-value="Teatro">TEATRO</a>
                        </li>
                        <li className="nav-item">
                            <a className="nav-link" href="/tempo-fa" data-value="Com'eravamo">COM'ERAVAMO</a>
                        </li>
                    </ul>
                </div>
            </nav>
            {title && (
                <div className="header-title-container">
                    <h1 className='header-title'>{title}</h1>
                </div>
            )
            }
        </>
    )
}

export default Header
