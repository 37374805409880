import { useEffect, useRef, useState } from "react";
import "./Player.css"
import Song from "../types/Song";
import UserData from "../types/UserData";

interface PlayerProps {
    songs: Song[],
    imageSrc: string,
    title: string,
    presentation?: string,
}
const Player: React.FC<PlayerProps> = ({ songs, imageSrc, title, presentation }) => {
    const [isChanging, setIsChanging] = useState<boolean>(false);
    const [isPlaying, setIsPlaying] = useState<boolean | null>(null);

    const playlist = useRef<HTMLUListElement>(null);

    const allSongs = [...songs];

    const [audio] = useState(new Audio());
    const [userData, setUserData] = useState<UserData>({
        songs: [...allSongs],
        songCurrentTime: 0,
    });
    const [currentSong, setCurrentSong] = useState<Song | null>(null);

    useEffect(() => {
        highlightCurrentSong();
    }, [currentSong]);

    useEffect(() => {
        audio.addEventListener("timeupdate", handleBar);
        audio.addEventListener("ended", playNextSong);
        audio.addEventListener("handleLoadedMetadata", handleLoad);
        function handleLoad() {
            audio.play();
            audio.removeEventListener("handleLoadedMetadata", handleLoad);
        }

        return () => {
            audio.removeEventListener("timeupdate", handleBar);
            audio.removeEventListener("ended", playNextSong);
        };
    }, [audio.src]);

    const handleSongChange = (song: Song) => {
        setIsChanging(true);
        setIsPlaying(false);

        setTimeout(() => {
            setCurrentSong(song);
        }, 800)
    }

    const playSong = (id: number) => {
        const song = userData.songs.find((song) => song.id === id)!;
        if (currentSong === null || currentSong.id !== song.id) {
            audio.currentTime = 0;
        } else {
            audio.currentTime = userData?.songCurrentTime;
        }
        setTimeout(() => {
            audio.src = song.src;
            audio.title = song.title;

            setIsPlaying(true);
            setIsChanging(false);
            audio.play();
        }, 1100)
    };

    const pauseSong = () => {
        setUserData({ ...userData, songCurrentTime: audio.currentTime })
        setIsPlaying(false);
        audio.pause();
    };

    const playNextSong = () => {
        audio.pause();
        const currentSong = getCurrentSongIndex() ?? -1;
        const nextSong = userData.songs[currentSong === allSongs.length - 1 ? 0 : currentSong + 1];
        handleSongChange(nextSong);
        playSong(nextSong.id);
    };

    const playPreviousSong = () => {
        audio.pause();
        const currentSongIndex = getCurrentSongIndex() || allSongs.length;
        const previousSong = userData.songs[currentSongIndex - 1];
        handleSongChange(previousSong);
        playSong(previousSong.id);
    };

    const highlightCurrentSong = () => {
        const playlistSongElements = Array.from(playlist.current?.children ?? [])

        playlistSongElements?.forEach((songEl) => {
            songEl.removeAttribute("aria-current");
        });
        playlistSongElements.find(songEl => songEl.getAttribute("id") === "song-" + currentSong?.id)?.setAttribute("aria-current", "true");
    };
    const getCurrentSongIndex = () => currentSong && userData?.songs.indexOf(currentSong);

    const handlePlay = () => {
        switch (isPlaying) {
            case true:
                return;
            case false:
                audio.play();
                setIsPlaying(true);
                break;
            case null:
                handleSongChange(allSongs[0]);
                playSong(0);
                break;
            default:
                playSong(currentSong ? currentSong.id : userData.songs[0].id);
                console.log(currentSong?.src);
        }

    }

    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        window.scrollTo(0, 0);
        const currentSongId = event.currentTarget.parentElement?.id.replace("song-", "");
        if (currentSong?.id === currentSongId) return;
        audio.pause();

        handleSongChange(userData.songs.find(song => song.id === +currentSongId!)!);
        playSong(+currentSongId!);
    }

    const handleBar = () => {
        setUserData({ ...userData, songCurrentTime: audio.currentTime });
    }
    const handleBarChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        audio.currentTime = +e.target.value;
    }
    const formatTime = (time: number) => {
        const minutes = Math.floor(time / 60);
        const seconds = Math.floor(time % 60);
        return `${minutes}:${seconds < 10 ? '0' : ''}${seconds}`;
    };
    return (
        <div className="player__wrapper">
            <div className="player">
                <div className="player-content">
                    <div id="album-img-container">
                        <div id="player-album-art">
                            <img id="player-album-img" className={`${isPlaying ? "playing" : ""}`} src={imageSrc} alt="song cover art" />
                        </div>
                    </div>
                    <div id="player-display__wrapper">
                        <div id="player-display" className={`${title === "InCantu di Storie" ? " alt" : ""}`}>

                            <div className="player-buttons">
                                <button id="previous" className="previous" aria-label="Previous" onClick={playPreviousSong}>
                                    <svg
                                        className="player-buttons__svg"
                                        viewBox="0 0 24 19"
                                        fill="none"
                                        xmlns="http://www.w3.org/2000/svg"
                                    ><path d="M23.2248 0L7.03964 9.5L23.2248 19L23.2248 0Z" /><rect
                                            width="4.63633"
                                            height="18.5453"
                                            transform="matrix(-1 0 0 1 4.63633 0)" /></svg>
                                </button>
                                <button id="play" className={`play${isPlaying ? " playing" : ""}`} aria-label="Play" onClick={handlePlay}>
                                    <svg
                                        className="player-buttons__svg"
                                        viewBox="0 0 17 19"
                                        fill="none"
                                        xmlns="http://www.w3.org/2000/svg"
                                    > <path d="M0 0L16.1852 9.5L1.88952e-07 19L0 0Z" />
                                    </svg>
                                </button>
                                <button id="pause" className={`pause${!isPlaying ? " playing" : ""}`} aria-label="Pause" onClick={pauseSong}>
                                    <svg
                                        className="player-buttons__svg"
                                        viewBox="0 0 17 19"
                                        fill="none"
                                        xmlns="http://www.w3.org/2000/svg"
                                    ><path d="M0 6.54013e-07H4.75V19H0V6.54013e-07Z" /> <path d="M11.4 0H16.15V19H11.4V0Z" />
                                    </svg>
                                </button>
                                <button id="next" className="next" aria-label="Next" onClick={playNextSong}>
                                    <svg
                                        className="player-buttons__svg"
                                        viewBox="0 0 24 19"
                                        fill="none"
                                        xmlns="http://www.w3.org/2000/svg"
                                    ><path d="M0 0L16.1852 9.5L1.88952e-07 19L0 0Z" /> <rect x="18.5885" width="4.63633" height="18.5453" />
                                    </svg>
                                </button>
                            </div>
                            <div className={`player-display-song-artist${isChanging ? " changing" : ""}`}>
                                <p id="player-song-title">{currentSong?.title ?? ""}</p>
                                <p id="player-song-artist">{currentSong?.artist ?? ""}</p>
                            </div>
                        </div>
                    </div>

                </div>
                <div className="progress-bar__container">
                    <p className="progress-bar__time-current">{"0" + formatTime(userData.songCurrentTime)}</p>
                    <input type="range" min="0" max={String(audio.duration)} value={userData.songCurrentTime} onChange={handleBarChange} id="progress-bar__track" />
                    <p className="progress-bar__time">{"0" + (currentSong?.duration ?? ":00")}</p>
                </div>
            </div>

            {/* Presentazione Album */}
            {presentation && (
                <div className="playlist-presentation">
                    <div className="playlist p-0 d-block">
                        <div className="playlist-header">
                            <div className="accordion" id="accordionPanelsStayOpenExample">
                                <div className="accordion-item">
                                    <h2 className="accordion-header bg-none">
                                        <button className="accordion-button p-0 bg-secondary text-light" type="button" data-bs-toggle="collapse" data-bs-target="#panelsStayOpen-collapseOne" aria-expanded="true" aria-controls="panelsStayOpen-collapseOne">
                                            <div id="presentation__header" className="mx-1">Presentazione Album</div>
                                        </button>
                                    </h2>
                                    <div id="panelsStayOpen-collapseOne" className="accordion-collapse collapse">
                                        <div className="accordion-body bg-dark text-light" dangerouslySetInnerHTML={{ __html: presentation }}>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="presentation__content">
                            
                        </div>
                    </div>
                </div>
            )}


            <div className="playlist">
                <div className="playlist-bar">
                    <div className="parallel-lines">
                        <div></div>
                        <div></div>
                    </div>
                    <h2 className="playlist-title" id="playlist">{title}</h2>
                    <div className="parallel-lines">
                        <div></div>
                        <div></div>
                    </div>
                </div>
                <ul id="playlist-songs" ref={playlist}>
                    {allSongs.map((song, index) => {
                        return (
                            <li id={`song-${song.id}`} key={"0" + index} className="playlist-song">
                                <button className="playlist-song-info" onClick={(event) => { handleClick(event); }}>
                                    <span className="playlist-song-title">{song.title}</span>
                                    <div className="playlist-song-details">
                                        <span className="playlist-song-artist">{song.artist}</span>
                                        <span className="playlist-song-duration">{song.duration}</span>
                                    </div>
                                </button>
                            </li>
                        )
                    })}
                </ul>
            </div>
        </div >

    )
}

export default Player