const insAnc = [
    {
        id: 0,
        title: "Cuore",
        artist: "Mario Puglisi",
        duration: "4:22",
        src: "./assets/insanc/1-Cuore.mp3",
    },
    {
        id: 1,
        title: "Dove vola l'aquilone",
        artist: "Mario Puglisi",
        duration: "4:31",
        src: "./assets/insanc/2-Dove-vola-laquilone.mp3",
    },
    {
        id: 2,
        title: "Senti",
        artist: "Mario Puglisi",
        duration: "4:39",
        src: "./assets/insanc/3-Senti.mp3",
    },
    {
        id: 3,
        title: "Le cose intorno a me",
        artist: "Mario Puglisi",
        duration: "3:57",
        src: "./assets/insanc/4-Le-cose-intorno-a-me.mp3",
    },
    {
        id: 4,
        title: "Novembre",
        artist: "Mario Puglisi",
        duration: "3:24",
        src: "./assets/insanc/5-Novembre.mp3",
    },
    {
        id: 5,
        title: "E raccontalo",
        artist: "Mario Puglisi",
        duration: "4:53",
        src: "./assets/insanc/6-E-raccontalo.mp3",
    },
    {
        id: 6,
        title: "Torna cu mia",
        artist: "Mario Puglisi",
        duration: "4:42",
        src: "./assets/insanc/7-Torna-cu-mia.mp3",
    },
    {
        id: 7,
        title: "L'impossibile esiste",
        artist: "Mario Puglisi",
        duration: "4:43",
        src: "./assets/insanc/8-L-impossibile-esiste.mp3",
    },
    {
        id: 8,
        title: "Bussa Piano",
        artist: "Mario Puglisi",
        duration: "3:36",
        src: "./assets/insanc/9-Bussa-Piano.mp3",
    },
    {
        id: 9,
        title: "Insegnami Ancora",
        artist: "Mario Puglisi",
        duration: "5:19",
        src: "./assets/insanc/10-Insegnami-Ancora.mp3",
    },
];

export default insAnc