import "./Footer.css"

const Footer = () => {
    return (
        <>
            <div className="footer">
                <p className="footer__end-text">Copyright © {new Date().getFullYear()} Mario Puglisi</p>
                <div className="footer__elems">
                    <div className="footer__pages">
                        <p className="footer__title">Pagine</p>
                        <a href="/" className="footer__link">HOME</a>
                        <a href="/cantautore" className="footer__link">CANTAUTORE</a>
                        <a href="/teatro" className="footer__link">TEATRO</a>
                        <a href="/tempo-fa" className="footer__link">COM'ERAVAMO</a>
                    </div>
                    <div className="footer__socials">
                        <p className="footer__title">Contatti</p>
                        <p className="footer__text">mariopuglisi@libero.it</p>
                        <a href="https://www.facebook.com/mario.puglisi2/" className="footer__text fb-link">Facebook</a>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Footer
