import React from 'react';
interface YoutubeProps {
  videoId: string;
  title: string;
}
const YouTubeEmbed: React.FC<YoutubeProps> = ({ videoId, title }) => {
  return (
      <iframe
        className={`youtube-element`}
        src={`https://www.youtube.com/embed/${videoId}`}
        title={title}
        frameBorder="0"
        allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
        allowFullScreen
      ></iframe>
  );
};

export default YouTubeEmbed;