import PageProps from "../types/PageProps"
import YouTubeEmbed from "../components/HomePage/Youtube/Youtube"
import useScroll from "../hooks/useScroll"
import Header from "../components/HomePage/Header/Header"
import Sidebar from "../components/HomePage/Sidebar/Sidebar"
import ButtonToTop from "../components/ButtonToTop/ButtonToTop"
import Footer from "../components/HomePage/Footer/Footer"

const Cantautore: React.FC<PageProps> = ({ targetRef, active, setActive }) => {
  useScroll(targetRef, setActive)
  return (
    <>
    <Header active={active}/>
      <Sidebar />
      <div className="main-page__container inv-bg">
        <div className="main-page__text-wrapper">
          <p className="main-page__text">Vincitore dell&#39;ottava edizione del <span className="main-page__text__span">Festival della nuova canzone siciliana</span> quando il presidente di giuria è <strong>Franco Battiato</strong>.
            <br /><br />
            Negli anni &#39;90, prodotto dalla RCA Italiana, sfiora la partecipazione al festival di Sanremo con un proprio brano arrangiato da <em>Ennio Morricone</em>.
            <br />
            Partecipa ad alcuni programmi RAI con <em>Gabriella Carlucci, Atina Cenci</em>, e registra oltre 12 puntate con <em>Renato Zero</em>.
            <br /><br />
            Ha scritto canzoni per altri interpreti come <span className="main-page__text__span">Francesca Alotta,
              Maria Corso, Ernesto Marciante…</span> scrive inoltre un brano per Ana Flora e Fabio Concato: <em><u>“Dove vola l&#39;aquilone”</u></em>.
            <br /><br />
            Nel 2012 esce, prodotto e arrangiato da Riccardo Samperi e Giuseppe Furnari, il suo primo album: “Insegnami ancora”: dieci brani immersi in un jazz velato, dai profumi mediterranei.
            <br /><br />
            Nel 2023 esce su tutte le piattaforme il suo singolo “A sirena d&#39;Auruca”. Un brano liberamente tratto dal racconto di Tomasi di Lampedusa “La sirena”, del quale è anche arrangiatore.</p>
        </div>
        <div className="main-page__image-container"><img className="main-page__image" src="./images/MP_GBG.jpg" alt="Mario Puglisi" ref={targetRef}/></div>
      </div>

      <div className="embeds-wrapper d-flex">
        <figure className="youtube-embed__container">
          <YouTubeEmbed videoId="be0XIfpPU5k" title="Dove vola l'aquilone" />
          <figcaption className="embed__text mt-2">Ana Flora feat Fabio Concato &#8211; Dove vola l&#39;aquilone</figcaption>
        </figure>
        <figure className="youtube-embed__container">
          <YouTubeEmbed videoId="4HMPmXj4-R0" title="Torna cu mia" />
          <figcaption className="embed__text mt-2">2010: Festival della Nuova Canzone Siciliana presentato da Salvo La Rosa &#8211; “Torna cu mia”</figcaption>
        </figure>
      </div>
      <ButtonToTop active={active} />
      <Footer />
    </>
  )
}

export default Cantautore
