const Incantu = [
    {
        id: 0,
        title: "A sirena d'Auruca",
        artist: "Mario Puglisi",
        duration: "3:54",
        src: "/assets/incantu/01 A sirena d'Auruca.mp3",
    },
    {
        id: 1,
        title: "Amuri Orrori e Basilicò",
        artist: "Mario Puglisi",
        duration: "4:24",
        src: "./assets/incantu/02 Amuri Orrori e basilicò.mp3",
    },
    {
        id: 2,
        title: "Canto dei Salinari",
        artist: "Mario Puglisi",
        duration: "4:18",
        src: "./assets/incantu/03 Canto dei salinari.mp3",
    },
    {
        id: 3,
        title: "Lu corpu e lu spiritu",
        artist: "Mario Puglisi",
        duration: "3:48",
        src: "./assets/incantu/04 Lu corpu e lu spiritu.mp3",
    },
    {
        id: 4,
        title: "Ma picchi semu cà",
        artist: "Mario Puglisi",
        duration: "4:49",
        src: "./assets/incantu/05 Ma picchi semu cà.mp3",
    },
    {
        id: 5,
        title: "Nica",
        artist: "Mario Puglisi",
        duration: "4:48",
        src: "./assets/incantu/06 Nica.mp3",
    },
    {
        id: 6,
        title: "Tarocchi e almanacchi",
        artist: "Mario Puglisi",
        duration: "3:37",
        src: "./assets/incantu/07 Tarocchi e almanacchi.mp3",
    },
    {
        id: 7,
        title: "Rosa è cà",
        artist: "Mario Puglisi",
        duration: "5:35",
        src: "./assets/incantu/08 Rosa è cà.mp3",
    },
    {
        id: 8,
        title: "Peppa a cannunera",
        artist: "Mario Puglisi",
        duration: "5:07",
        src: "./assets/incantu/09 Peppa a cannunera.mp3",
    },
    {
        id: 9,
        title: "Arancinu o arancina",
        artist: "Mario Puglisi",
        duration: "4:29",
        src: "./assets/incantu/10 Arancinu o arancina.mp3",
    },
];

export default Incantu