const Salemi = [
  {
    id: 0,
    title: "Over the rainbow",
    artist: "Gaetano Salemi",
    duration: "3:14",
    src: "./assets/salemi/1-Over-the-rainbow.mp3"
  },
  {
    id: 1,
    title: "Fly me to the moon",
    artist: "Gaetano Salemi",
    duration: "2:34",
    src: "./assets/salemi/2-Fly-me-to-the-moon.mp3"
  },
  {
    id: 2,
    title: "Ho capito che ti amo",
    artist: "Gaetano Salemi",
    duration: "4:43",
    src: "./assets/salemi/3-Ho-capito-che-ti-amo.mp3"
  },
  {
    id: 3,
    title: "A te",
    artist: "Gaetano Salemi",
    duration: "3:32",
    src: "./assets/salemi/4-A-te.mp3"
  },
  {
    id: 4,
    title: "Something Stupid",
    artist: "Gaetano Salemi",
    duration: "2:35",
    src: "./assets/salemi/5-Something-Stupid.mp3"
  },
  {
    id: 5,
    title: "Amore che vieni",
    artist: "Gaetano Salemi",
    duration: "2:51",
    src: "./assets/salemi/6-Amore-che-vieni.mp3"
  },
  {
    id: 6,
    title: "Bocca di rosa",
    artist: "Gaetano Salemi",
    duration: "3:21",
    src: "./assets/salemi/7-Bocca-di-rosa.mp3"
  },
  {
    id: 7,
    title: "Medley dei ricordi",
    artist: "Gaetano Salemi",
    duration: "3:23",
    src: "./assets/salemi/8-Medley-dei-ricordi.mp3"
  },
  {
    id: 8,
    title: "La canzone di Marinella",
    artist: "Gaetano Salemi",
    duration: "4:05",
    src: "./assets/salemi/9-La-canzone-di-Marinella.mp3"
  },
  {
    id: 9,
    title: "Amore disperato",
    artist: "Gaetano Salemi",
    duration: "3:37",
    src: "./assets/salemi/10-Amore-disperato.mp3"
  }
]

export default Salemi;