import ButtonToTop from '../components/ButtonToTop/ButtonToTop';
import Footer from '../components/HomePage/Footer/Footer';
import Header from '../components/HomePage/Header/Header';
import Main from '../components/HomePage/Main/Main';
import Sidebar from '../components/HomePage/Sidebar/Sidebar';
import PageProps from '../types/PageProps';

const HomePage: React.FC<PageProps> = ({ targetRef, active, setActive }) => {

    return (
        <>
            <Header active={active} />
            <Sidebar />
            <Main targetRef={targetRef} setActive={setActive} active={active} />
            <ButtonToTop active={active} />
            <Footer />
        </>
    )
}

export default HomePage
