import { useEffect } from 'react';

const useScroll = (ref: React.RefObject<HTMLImageElement> | React.RefObject<HTMLDivElement>, callback: Function) => {
  useEffect(() => {
    const handleScroll = () => {
      if (ref.current) {
        const targetPosition = ref.current.getBoundingClientRect().top;
        callback(targetPosition <= 0 ? true : false);
      }
    };

    document.addEventListener('scroll', handleScroll);

    return () => {
      document.removeEventListener('scroll', handleScroll);
    };
  }, [ref, callback]);
};

export default useScroll;
