import "./Sidebar.css"

const Sidebar = () => {
    return (
        <div className="offcanvas offcanvas-start" tabIndex={-1} id="offcanvasExample" aria-labelledby="offcanvasExampleLabel">
            <div className="offcanvas-header">
                <button type="button" className="btn-close text-reset" data-bs-dismiss="offcanvas" aria-label="Close"></button>
            </div>
            <h5 className="offcanvas-title" id="offcanvasExampleLabel">Mario Puglisi</h5>
            <hr className="offcanvas-hr"></hr>
            <div className="offcanvas-body">
                <ul className="offcanvas-link-list">
                    <li className="offcanvas-item">
                        <a className="offcanvas-link" href="/" data-value={null}>HOME</a>
                    </li>
                    <li className="offcanvas-item">
                        <a className="offcanvas-link" href="/cantautore" data-value="Cantautore">CANTAUTORE</a>
                    </li>
                    <li className="offcanvas-item">
                        <a className="offcanvas-link" href="/teatro" data-value="Teatro">TEATRO</a>
                    </li>
                    <li className="offcanvas-item">
                        <a className="offcanvas-link" href="/tempo-fa" data-value="Com'eravamo">COM'ERAVAMO</a>
                    </li>
                </ul>
            </div>
        </div>
    )
}

export default Sidebar
