import React from 'react'
import PageProps from '../types/PageProps'
import useScroll from '../hooks/useScroll'
import YouTubeEmbed from '../components/HomePage/Youtube/Youtube'
import Header from '../components/HomePage/Header/Header'
import Sidebar from '../components/HomePage/Sidebar/Sidebar'
import ButtonToTop from '../components/ButtonToTop/ButtonToTop'
import Footer from '../components/HomePage/Footer/Footer'

const Passato: React.FC<PageProps> = ({ targetRef, active, setActive }) => {
  useScroll(targetRef, setActive)
  return (
    <>
      <Header active={active}/>
      <Sidebar />
      <div className="main-page__container d-flex flex-column" ref={targetRef}>
        <div className="main-page__header-container">
          <p className="main-page__header">...Quando il mondo girava in un altro modo</p>
        </div>
        <div className="main-page__content-wrapper d-flex sing-cont">
        <figure className="youtube-embed__container">
            <YouTubeEmbed videoId="lItabbHRM_k" title="Primu amuri"/>
        </figure>
        <div className="main-page__text-wrapper">
          <p className="main-page__text main-page__content">
            <strong>1984: Festival della nuova canzone siciliana presentato da Pippo Baudo.</strong><br />  "Primu amuri". Perfomance premiata dalla giuria come "canzone e coppia rivelazione dell'anno"
          </p>
        </div>
        </div>
      </div>
      <ButtonToTop active={active} />
      <Footer />
    </>
  )
}

export default Passato
